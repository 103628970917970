<template>
  <div class="doc-edait">
    <div class="doc-main">
      <el-form ref="formData" label-width="120px">
        <el-form-item label="文档名称:" required>
          <el-input v-model.trim="docData.name" placeholder="中英文限20字，必填" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="封面图" required>
          <el-upload
            class="up-btn"
            :http-request="uploadCover"
            action="String"
            :beforeUpload="beforeRemove"
            multiple
            :show-file-list="false"
          >
            <el-button type="primary">点击上传</el-button>
          </el-upload>
          <div class="person-img">
            <el-image class="pre" :src="docData.coverPath" :preview-src-list="[docData.coverPath]"></el-image>
          </div>

          <el-alert title="只能上传一张,1M以下,多次上传会覆盖。" type="warning" :closable="false"></el-alert>
        </el-form-item>
        <el-form-item label="文档标题:" required>
          <el-input
            v-model.trim="docData.title"
            placeholder="中英文限50字，必填"
            type="textarea"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="文档简述:">
          <el-input
            v-model.trim="docData.sketch"
            type="textarea"
            placeholder="中英文限200字，支持分行，非必填"
            maxlength="200"
          ></el-input>
        </el-form-item>
        <el-form-item label="宣传文案:">
          <el-input v-model.trim="docData.copywriting" placeholder="中英文限20字，非必填" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="作者:">
          <el-input v-model.trim="docData.author" placeholder="中英文限20字，非必填" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="上传头像:" required>
          <el-upload
            class="up-btn"
            :http-request="uploadPerson"
            action="String"
            :beforeUpload="beforeRemove"
            multiple
            :show-file-list="false"
          >
            <el-button type="primary">点击上传</el-button>
          </el-upload>
          <div class="person-img">
            <el-image
              class="pre"
              :src="docData.authorPath"
              :preview-src-list="[docData.authorPath]"
            ></el-image>
          </div>
          <el-alert title="只能上传一张，必填，1M以下，多次上传会覆盖。" type="warning" :closable="false"></el-alert>
        </el-form-item>

        <el-form-item label="行业:" required>
          <el-radio v-model="query.tradeFlag" label="0">不限行业</el-radio>
          <el-radio v-model="query.tradeFlag" label="1">指定行业</el-radio>
          <div class="trade" v-if="query.tradeFlag== '1'">
            <el-button type="primary" @click="showLabelDialog('trade',0)">添加行业</el-button>
            <div class="trade-table" v-if="allIndustry.length > 0">
              <el-table
                :data="allIndustry"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
              >
                <el-table-column prop="name" label="行业名称" align="center"></el-table-column>
                <el-table-column label="操作" width="360" align="center">
                  <template #default="scope">
                    <el-button
                      type="text"
                      icon="el-icon-edit"
                      @click="showLabelDialog('trade',1)"
                    >修改</el-button>
                    <el-button
                      type="text"
                      icon="el-icon-circle-close"
                      class="red"
                      @click="dealData('trade',scope)"
                    >删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="职业:" required>
          <el-radio v-model="query.occFlag" label="0">不限职业</el-radio>
          <el-radio v-model="query.occFlag" label="1">指定职业</el-radio>
          <div class="occ" v-if="query.occFlag== '1'">
            <el-button type="primary" @click="showLabelDialog('occ',0)">添加职业</el-button>
            <div class="occ-table" v-if="allOcc && allOcc.length  > 0">
              <el-table
                :data="allOcc"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
              >
                <el-table-column prop="occuName" label="职业名称" align="center"></el-table-column>
                <el-table-column prop="posiName" label="职位名称" align="center"></el-table-column>
                <el-table-column label="操作" width="360" align="center">
                  <template #default="scope">
                    <el-button type="text" icon="el-icon-edit" @click="showLabelDialog('occ',1)">修改</el-button>
                    <el-button
                      type="text"
                      icon="el-icon-circle-close"
                      class="red"
                      @click="dealData('occ',scope)"
                    >删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="场景:" required>
          <el-button type="primary" class="pay-btn" @click="showLabelDialog('sence',0)">添加</el-button>

          <el-alert title="不填或填0代表无限" type="warning" :closable="false"></el-alert>
          <p style="color: red" v-if="sceneIdsList.length<=0">暂未添加场景</p>
          <div class="trade-table pay-table" v-else>
            <el-table
              :data="sceneIdsList"
              border
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
            >
              <el-table-column prop="name" label="会员名称" align="center"></el-table-column>
              <el-table-column label="操作" width="360" align="center">
                <template #default="scope">
                  <el-button type="text" icon="el-icon-edit" @click="showLabelDialog('sence',1)">修改</el-button>
                  <el-button
                    type="text"
                    icon="el-icon-circle-close"
                    class="red"
                    @click="dealData('sence',scope)"
                  >删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
        <el-form-item label="薪资:" required>
          <el-input v-model.trim="docData.salary" placeholder="不填或填0代表不限"></el-input>
        </el-form-item>
        <el-form-item label="感兴趣人数:" required>
          <el-input v-model.trim="docData.interested" placeholder="默认为空，则算作0，可填写数字，非必填"></el-input>
        </el-form-item>
        <el-form-item label="下载需积分:" required>
          <el-input v-model.trim="docData.points" placeholder="填数字，不填或填0代表免费解锁"></el-input>
        </el-form-item>

        <el-form-item label="需要会员:" required>
          <el-button type="primary" class="pay-btn" @click="showLabelDialog('pay',0)">添加</el-button>

          <el-alert title="选择会员，可多选，不选择则检查是否有上一级会员" type="warning" :closable="false"></el-alert>

          <div class="trade-table pay-table" v-if="membersIdsList.length > 0">
            <el-table
              :data="membersIdsList"
              border
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
            >
              <el-table-column prop="productName" label="会员名称" align="center"></el-table-column>
              <el-table-column label="操作" width="360" align="center">
                <template #default="scope">
                  <el-button type="text" icon="el-icon-edit" @click="showLabelDialog('pay',1)">修改</el-button>
                  <el-button
                    type="text"
                    icon="el-icon-circle-close"
                    class="red"
                    @click="dealData('pay',scope)"
                  >删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
        <el-form-item label="是否推荐:" required>
          <el-radio v-model="query.vip" label="1">是</el-radio>
          <el-radio v-model="query.vip" label="0">否</el-radio>
        </el-form-item>
        <el-form-item label="资源:" required>
          <el-upload
            class="up-btn"
            :http-request="uploadFile"
            action="String"
            :beforeUpload="beforeFile"
            multiple
            :show-file-list="false"
          >
            <el-button type="primary">点击上传</el-button>
          </el-upload>
          <div>
            <a :href="docData.docPath" target="_blank" download="文档">{{docData.docPath }}</a>
          </div>

          <el-alert title="资源只能上传一个" type="warning" :closable="false"></el-alert>
        </el-form-item>
      </el-form>
    </div>
    <div class="desc-main">正文内容</div>
    <!-- 富文本编辑框 -->
    <TEditor ref="editor" v-model="value" />
    <!-- 富文本结束 -->
    <div class="submit">
      <el-button type="primary" class="save" @click="savaData">保存文档</el-button>
    </div>
    <!--  -->
    <el-dialog v-model="showDialog" :title="eaditTitle" class="label-main" width="40%" center>
      <div class="dialog">
        <div class="select" v-if="type=='occ'">
          <el-select v-model="fatherName" placeholder="请选择职业">
            <el-option v-for="(item,i) in fatherList" :key="i" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-button type="primary" @click="getPosiList" class="search-label">搜索</el-button>
          <p style="color: red;paddingTop: 10px">请先选择职业进行筛选数据</p>
        </div>
        <div class="dialog-top" v-else>
          <el-input v-model.trim="query.name" placeholder="搜索ID，名称"></el-input>
          <el-button type="primary" class="search-label" @click="searchBoxData">搜索</el-button>
        </div>
        <p v-if="dialogData.lengtn <= 0">暂无数据</p>
        <div class="dialog-main">
          <ul class="label">
            <li class="label-item" @click="addTag(item)" v-for="(item,i) in dialogData " :key="i">
              <div
                v-if="type == 'pay'"
              >ID: {{item.productId}}--名称: {{item.productName}}, 天数： {{ item.orderTypeRemark}},每日次数： {{item.dailyTime }}</div>
              <div v-else>ID: {{item.id}}--名称: {{item.name}}</div>
            </li>
          </ul>

          <div class="page" v-if="dialogTotal>6">
            <el-pagination
              background
              layout=" prev, pager, next"
              :current-page="pageIndex"
              :page-size="6"
              :total="dialogTotal"
              @current-change="handlePageChange"
            ></el-pagination>
          </div>
        </div>
        <div class="dialog-bottom">
          <div class="bottom-top">
            已选:
            <span style="color: red" v-if="tags.length <= 0">暂未选中数据</span>
            <div class="bottom-box" v-else>
              <div v-if="type == 'pay'">
                <el-tag
                  v-for="(tag,i) in tags"
                  @close="closeTag(i)"
                  class="tag"
                  :key="tag.name"
                  closable
                >{{ tag.productName }}</el-tag>
              </div>

              <div v-else>
                <el-tag
                  v-for="(tag,i) in tags"
                  @close="closeTag(i)"
                  class="tag"
                  :key="tag.name"
                  closable
                >{{type =="occ"? tag.occuName +"--"+tag.posiName : tag.name }}</el-tag>
              </div>
            </div>
          </div>
          <div class="bottom-btn">
            <el-button @click="showDialog = false">取消</el-button>
            <el-button class="lable-btn" @click="okDialog " type="primary">选好了</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// getDocChange
import {
  getDocData,
  getAllIndustry,
  getAllOcc,
  getUploadMh,
  getDocChange,
  getTradeList,
  GetAllPosiList,
  getSenceList,
  getAllSenceList,
  getAllVipList,
  getVipList
} from "../../api/index";

export default {
  name: "docEadit",
  data() {
    return {
      value: "请输入",
      id: "", // 文档id
      docData: {
        id: "",
        name: "",
        title: "",
        sketch: "",
        copywriting: "",
        author: "",
        industryIds: "",
        occupationIds: "",
        positionIds: "",
        sceneIds: "",
        salary: 0,
        interested: 0,
        points: 0,
        membersIds: "",
        coverPath: "",
        authorPath: "",
        docPath: "",
        domain: "",
        text: "",
        isRecommend: true,
        isEnable: true
      },
      allIndustry: [], // 选中行业
      allOcc: [], // 选中职业
      allPosition: [], // 选中职位
      query: {
        tradeFlag: "1",
        occFlag: "1",
        name: "",
        vip: "1",
        pageIndex: 1
      },
      eaditTitle: "添加行业",
      showDialog: false,
      pageIndex: 1, //当前页
      total: 1000, // 总页数
      tags: [],
      occList: [{ name: "制造业" }, { name: "教育" }],
      type: "trade",
      fatherName: "",
      imgeUrl: "https://cdn.aidaxue.com/aicollege/16290827487239431.png",
      dialogData: [],
      dialogTotal: 0,
      sceneIdsList: [],
      membersIdsList: [] // 会员
    };
  },

  created() {
    this.id = this.$route.query.id;
    if (this.id) {
      this.getData();
    }
    console.log(this.$route.query.id, "获取id");
  },
  mounted() {},
  methods: {
    // 弹框选择
    showLabelDialog(type, flag) {
      this.dialogData = [];
      this.dialogTotal = 0;
      this.tags = [];
      this.query.name = "";
      console.log(type, flag);
      if (type == "trade") {
        this.tags = this.allIndustry.slice(0);
        this.getTradeData(); // 获取所有行业
        if (flag == 0) {
          this.eaditTitle = "添加行业";
        } else {
          this.eaditTitle = "修改行业";
        }
      } else if (type == "occ") {
        this.getAllOccList(); // 所有职业
        // this.getPosiList();// 所有职位
        if (this.allOcc) {
          this.tags = this.allOcc.slice(0);
        }

        if (flag == 0) {
          this.eaditTitle = "添加职业";
        } else {
          this.eaditTitle = "修改职业";
        }
      } else if (type == "pay") {
        this.tags = this.membersIdsList.slice(0);
        this.getVipListData();
        if (flag == 0) {
          this.eaditTitle = "添加会员";
        } else {
          this.eaditTitle = "修改会员";
        }
      } else if (type == "sence") {
        this.tags = this.sceneIdsList.slice(0);
        this.query.pageIndex = 1; // 默认为第一页
        this.getSenceData();
        if (flag == 0) {
          this.eaditTitle = "添加场景";
        } else {
          this.eaditTitle = "修改场景";
        }
      }
      this.type = type;
      this.showDialog = true;
    },
    // 弹框搜索数据
    searchBoxData() {
      this.query.pageIndex = 1;
      this.pageIndex = 1;
      if (this.type == "sence") {
        this.getSenceData();
      } else if (this.type == "trade") {
        this.getTradeData();
      } else if (this.type == "pay") {
        this.query.pageIndex = 1;
        this.pageIndex = 1;
        this.getVipListData();
      }
      this.$forceUpdate();
    },
    // 删除数据
    dealData(type, scope) {
      let i = scope.$index;
      //   console.log(type, row, 111);
      //   return;
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning"
      })
        .then(() => {
          if (type == "trade") {
            this.allIndustry.splice(i, 1);
          } else if (type == "occ") {
            this.allOcc.splice(i, 1);
          } else if (type == "pay") {
            this.membersIdsList.splice(i, 1);
          } else if (type == "sence") {
            this.sceneIdsList.splice(i, 1);
          }
        })
        .catch(() => {});

      console.log(type, scope);
    },
    // 分页
    handlePageChange(val) {
      console.log(val);
      this.pageIndex = val;
      this.query.pageIndex = val;
      let type = this.type;
      if (type == "trade") {
        this.getTradeData(); // 获取所有行业
      } else if (type == "occ") {
        this.getAllOccList(); // 所有职业
      } else if (type == "pay") {
        this.getVipListData();
      } else if (type == "sence") {
        this.getSenceData();
      }
    },
    // 上传图片校验
    beforeRemove(file, fileList) {
      console.log(fileList);
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "jpg" ||
        testmsg === "JPG" ||
        testmsg === "png" ||
        testmsg === "PNG";
      const isLt50M = file.size / 1024 / 1024 < 1;
      if (!extension) {
        this.$message({
          message: "上传文件只能是jpg或者png格式!",
          type: "error"
        });
        return false; //必须加上return false; 才能阻止
      }
      console.log(file);
      if (!isLt50M) {
        this.$message({
          message: "上传文件大小不能超过 1MB!",
          type: "error"
        });
        return false;
      }
      return extension && isLt50M;
    },
    beforeFile(file, fileList) {
      console.log(file, fileList);
      return true;
    },
    // 上传封面
    uploadCover(params) {
      const file = params.file;
      console.log(params, file, "888");
      var formdata = new FormData();
      formdata.append("file", file);
      getUploadMh(formdata).then(res => {
        console.log(res);
        this.docData.coverPath = res.data.url;
      });
    },
    // 上传头像图片
    uploadPerson(params) {
      const file = params.file;
      var formdata = new FormData();
      formdata.append("file", file);
      getUploadMh(formdata).then(res => {
        console.log(res);
        this.docData.authorPath = res.data.url;
      });
    },
    // 上传资源
    uploadFile(params) {
      const file = params.file;
      var formdata = new FormData();
      formdata.append("file", file);
      getUploadMh(formdata).then(res => {
        console.log(res);
        this.docData.docPath = res.data.url;
      });
    },

    //  保存
    savaData() {
      this.docData.text = this.$refs.editor.getText(); // 富文本重新赋值
      if (this.query.vip == "1") {
        this.docData.isRecommend = true;
      } else {
        this.docData.isRecommend = false;
      }
      // 必填项目校验
      let showMessage = txt => {
        this.$notify.warning({
          title: "提示",
          message: txt
        });
      };
      if (this.docData.name.length <= 0) {
        showMessage("请输入文档名称");
        return false;
      }
      if (this.docData.coverPath.length <= 0) {
        showMessage("请上传封面");
        return false;
      }
      if (this.docData.authorPath.length <= 0) {
        showMessage("请上传头像");
        return false;
      }
      if (this.docData.title.length <= 0) {
        showMessage("请输入文档标题");
        return false;
      }
      if (this.query.tradeFlag == "1" && this.allIndustry.length <= 0) {
        showMessage("请添加行业");
        return false;
      }
      if (this.query.occFlag == "1" && this.allOcc.length <= 0) {
        showMessage("请添加职业");
        return false;
      }
      let IndustryList = [];
      this.allIndustry.map(item => {
        IndustryList.push(item.id);
      });
      let IndustryStr = IndustryList.join(",");
      console.log(IndustryStr, " 行业id表");
      this.docData.industryIds = IndustryStr;

      let sceneIdsList = [];
      this.sceneIdsList.map(item => {
        sceneIdsList.push(item.id);
      });
      this.docData.sceneIds = sceneIdsList.join(",");
      let membersIdsList = [];
      this.membersIdsList.map(item => {
        membersIdsList.push(item.productId);
      });
      this.docData.membersIds = membersIdsList.join(",");

      this.allOcc &&
        this.allOcc.map(item => {
          item.id = "";
        });
      this.docData.occuPositList = this.allOcc; // 职业
      if (this.query.occFlag == "0") {
        this.docData.occuPositList = [];
      }
      if (this.query.tradeFlag == "0") {
        this.docData.industryIds = "";
      }
      //   路径处理
      if (this.docData.coverPath.length > 0) {
        let coverStart = this.docData.coverPath.indexOf(".com") + 4;
        this.docData.coverPath = this.docData.coverPath.slice(coverStart);
      }

      //   头像路径处理
      if (this.docData.authorPath.length > 0) {
        let authorStart = this.docData.authorPath.indexOf(".com") + 4;
        this.docData.authorPath = this.docData.authorPath.slice(authorStart);
      }
      //   文件路径处理
      if (this.docData.docPath.length > 0) {
        let docStart = this.docData.docPath.indexOf(".com") + 4;
        this.docData.docPath = this.docData.docPath.slice(docStart);
      }
      this.docData.domain = "https://pptres.qyzhuomian.com";

      let params = this.docData;
      getDocChange(params).then(res => {
        if (res.code == 0) {
          this.$notify.success({
            title: "提示",
            message: "操作成功"
          });
          this.getData();
        } else {
          this.$notify.warning({
            title: "提示",
            message: "操作失败"
          });
        }
      });
    },
    getData() {
      let params = {
        id: this.id
      };
      getDocData(params).then(res => {
        console.log(res);
        if (res.code === 0) {
          this.docData = res.data; //  回显数据
          this.value = this.docData.text; // 富文本编辑器内容;
          this.$refs.editor.initTiny(this.value); // 重新设置编辑器内容
          this.allOcc = this.docData.occuPositList; // 职位数组
          this.docData.industryIds.length <= 0
            ? (this.query.tradeFlag = "0")
            : (this.query.tradeFlag = "1");
          if (this.docData.occuPositList) {
            this.docData.occuPositList.length <= 0
              ? (this.query.occFlag = "0")
              : (this.query.occFlag = "1");
          } else {
            this.query.occFlag = "0";
          }
          this.docData.isRecommend
            ? (this.query.vip = "1")
            : (this.query.vip = "0"); // 是否推荐
          let industryList = this.docData.industryIds.split(",");
          this.sceneIdsList = this.docData.sceneIds.split(","); // 获取选中场景
          this.membersIdsList = this.docData.membersIds.split(","); // 获取选中会员
          this.docData.coverPath = this.docData.domain + this.docData.coverPath; // 封面
          this.docData.authorPath =
            this.docData.domain + this.docData.authorPath; // 头像
          this.docData.docPath = this.docData.domain + this.docData.docPath; // 文本
          // 获取所有行业
          let obj = {
            allIndustry: [],
            allOcc: [],
            allPosition: [],
            sceneIdsList: [],
            membersIdsList: []
          };
          let newObj = {
            allIndustry: [],
            allOcc: [],
            allPosition: [],
            sceneIdsList: [],
            membersIdsList: []
          };
          getAllIndustry().then(res => {
            if (res.code == 0) {
              obj.allIndustry = res.data;
              //   所有行业
              obj.allIndustry.map(item => {
                industryList.map(c => {
                  if (item.id == c) {
                    newObj.allIndustry.push(item);
                  }
                });
              });
              this.allIndustry = newObj.allIndustry; // 赋值
              console.log(newObj.allIndustry, " 结果数据");
            }
          });
          //   筛选所有场景
          getAllSenceList().then(res => {
            if (res.code == 0) {
              obj.sceneIdsList = res.data;
              //   所有行业
              obj.sceneIdsList.map(item => {
                this.sceneIdsList.map(c => {
                  if (item.id == c) {
                    newObj.sceneIdsList.push(item);
                  }
                });
              });
              this.sceneIdsList = newObj.sceneIdsList; // 赋值
              console.log(newObj.sceneIdsList, " 结果数据");
            }
          });
          //   帅选所有会员
          getAllVipList().then(res => {
            if (res.code == 0) {
              obj.membersIdsList = res.data;
              //   所有行业
              obj.membersIdsList.map(item => {
                this.membersIdsList.map(c => {
                  item.name = item.comboName;
                  if (item.productId == c) {
                    newObj.membersIdsList.push(item);
                  }
                });
              });
              this.membersIdsList = newObj.membersIdsList; // 赋值
              console.log(newObj.membersIdsList, " 结果数据");
            }
          });

          console.log(industryList, "数组");
        }
      });
    },
    // 获取所有行业
    getTradeData() {
      let params = {
        isEnable: true,
        name: this.query.name, //数字则查id，文字则查名称
        pageIndex: this.query.pageIndex,
        pageSize: 6
      };
      getTradeList(params).then(res => {
        if (res.code === 0) {
          this.dialogData = res.data.data;
          this.dialogData = this.dialogData.filter(item => {
            return item.enable == true;
          });
          this.dialogTotal = res.data.total;
        }
        console.log(res);
      });
    },

    // 所有职业
    getAllOccList() {
      getAllOcc().then(res => {
        if (res.code == 0) {
          this.fatherList = res.data;
        }
      });
    },
    // 所有职业
    getPosiList() {
      let params = {
        id: this.fatherName,
        isEnable: true
      };
      GetAllPosiList(params).then(res => {
        if (res.code == 0) {
          this.dialogData = res.data;
          if (this.dialogData != null && this.dialogData.length > 0) {
            this.dialogData = this.dialogData.filter(item => {
              return item.isEnable == true;
            });
          } else {
            this.dialogData = [];
          }
        }
      });
    },
    //弹窗--所有场景
    getSenceData() {
      let params = {
        isEnable: true,
        name: this.query.name, //数字则查id，文字则查名称
        pageIndex: this.query.pageIndex,
        pageSize: 6
      };
      getSenceList(params).then(res => {
        if (res.code === 0) {
          this.dialogData = res.data.data;
          this.dialogData = this.dialogData.filter(item => {
            return item.isEnable == true;
          });
          this.dialogTotal = res.data.total;
        }
        console.log(res);
      });
    },
    //弹窗--所有会员
    getVipListData() {
      let reg = /^[0-9]+.?[0-9]*$/; //判断字符串是否为数字 ，判断正整数用/^[1-9]+[0-9]*]*$/
      let productId = ""; // id
      let productName = "";
      if (!reg.test(this.query.name)) {
        // 字符串
        productName = this.query.name;
        productId = "";
      } else {
        // 数字
        productId = this.query.name;
        productName = "";
      }
      let params = {
        productName, //数字则查id，文字则查名称
        productId,
        pageIndex: this.query.pageIndex,
        pageSize: 6
      };
      getVipList(params).then(res => {
        if (res.code === 0) {
          this.dialogData = res.data.data;
          this.dialogData.map(item => {
            item.name = item.comboName;
          });
          this.dialogData = this.dialogData.filter(item => {
            return item.enable == true;
          });

          this.dialogTotal = res.data.total;
        }
        console.log(res);
      });
    },
    // 删除tag
    closeTag(i) {
      console.log(this.tags);
      this.tags.splice(i, 1);
      console.log(this.tags);
    },
    // 添加标签
    addTag(item) {
      if (this.type == "occ") {
        let occList = this.fatherList.filter(item => {
          return item.id == this.fatherName;
        });
        let obj = {
          docId: this.id,
          id: this.fatherName,
          occuId: this.fatherName,
          occuName: occList[0].name,
          posiId: item.id,
          posiName: item.name
        };
        let list = this.tags.filter(c => {
          return c.posiId === obj.posiId;
        });
        if (list.length > 0) {
          this.$notify.warning({
            title: "提示",
            message: "数据已经存在"
          });
          return false;
        }

        this.tags.push(obj);
      } else if (this.type == "pay") {
        let list = this.tags.filter(c => {
          return c.productId === item.productId;
        });
        if (list.length > 0) {
          this.$notify.warning({
            title: "提示",
            message: "数据已经存在"
          });
          return false;
        }

        this.tags.push(item);
      } else {
        let list = this.tags.filter(c => {
          return c.id === item.id;
        });
        if (list.length > 0) {
          this.$notify.warning({
            title: "提示",
            message: "数据已经存在"
          });
          return false;
        }

        this.tags.push(item);
      }

      console.log(this.allIndustry, "数据");
      this.$forceUpdate();
    },
    // 选择好了
    okDialog() {
      let type = this.type;
      if (type == "trade") {
        this.allIndustry = this.tags;
      } else if (type == "occ") {
        this.allOcc = this.tags;
      } else if (type == "pay") {
        this.membersIdsList = this.tags;
        console.log(1);
      } else if (type == "sence") {
        this.sceneIdsList = this.tags;
      }

      this.showDialog = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.doc-edait {
  .doc-main {
    width: 1200px;
    margin: 0 auto;
    .toolbar {
      border: 1px solid #ccc; /*设置下拉棒*/
    }

    .table {
      margin-top: 10px;
    }

    .pay-btn {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .dialog {
    .select {
      text-align: center;
    }
    .search-label {
      margin-left: 10px;
    }
    .dialog-top {
      display: flex;
    }
  }
  .desc-main {
    width: 100%;
    box-sizing: border-box;
    line-height: 40px;
    color: #fff;
    padding-left: 20px;
    background-color: #99a9bf;
  }
  .submit {
    padding-top: 20px;
    padding-bottom: 100px;
    width: 100%;
    text-align: center;
  }
  .dialog-main {
    padding: 20px;
    .label {
      list-style: none;
      .label-item {
        line-height: 40px;
        text-align: center;
        background-color: #eee;
        margin-bottom: 8px;
        cursor: pointer;
        &:hover {
          background-color: #ccc;
        }
      }
    }
    .page {
      width: 430px;
      margin: 0 auto;
    }
  }
  .dialog-bottom {
    padding-right: 20px;
    .bottom-box {
      display: inline-block;
      .tag {
        margin-right: 4px;
        margin-bottom: 4px;
      }
    }

    .bottom-btn {
      padding: 20px;
      text-align: center;
      .label-btn {
        margin-left: 20px;
      }
    }
  }
}

.trade-table ::v-deep .el-table td,
.trade-table ::v-deep .el-table th {
  padding: 0 !important;
}
.occ-table ::v-deep .el-table td,
.occ-table ::v-deep .el-table th {
  padding: 0 !important;
}
.doc-main ::v-deep .el-alert {
  padding: 0;
  margin: 6px;
}
.doc-main ::v-deep .el-dialog__title {
  font-weight: 600;
  color: #333;
}
.doc-main .up-btn {
  display: inline-block;
  vertical-align: middle;
}
.doc-main .up-btn .btn-text {
  display: inline-block;
  width: 100%;
  height: 100%;
  color: #fff;
  line-height: 32px;
}
.up-btn ::v-deep .el-upload {
  width: 98px;
  height: 40px;
  border: 0 !important;
  background-color: #409eff;
  line-height: 20px;
}
.up-btn >>> .el-button--small {
  width: 80px;
  height: 32px;
  border: 0 !important;
  background-color: #409eff;
}
.pre {
  width: 100px;
  height: 100px;
}
.toolbar {
  border: 1px solid #ccc; /*设置下拉棒*/
  height: 600px !important; /*!important是重点，因为原div是行内样式设置的高度300px*/
}
.toolbar ::v-deep .w-e-text-container {
  height: 600px !important; /*!important是重点，因为原div是行内样式设置的高度300px*/
}
.websiteEditorElem ::v-deep .w-e-text-container {
  height: 600px !important; /*!important是重点，因为原div是行内样式设置的高度300px*/
}
</style>